@import './styles/Colors.css';

* {
  margin: 0;
  padding: 0;
}

input,
label,
select,
textarea {
  font-family: 'Times New Roman',
    Times,
    serif !important;
}

label {
  font-size: 14px !important;
}

input,
textarea {
  font-size: 13px !important;
}

#userName,
#gcn,
#pcn,
#dcn,
#providerUserName,
#providerName {
  text-transform: none !important;
}


/* to remove input number btns */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


body {
  background-color: var(--background);

}

.react-datepicker__day {
  font-family: 'Segoe UI',
    Tahoma,
    Geneva,
    Verdana,
    sans-serif !important;

  color: var(--darkBlue) !important;

}

.react-datepicker__current-month {
  font-family: 'Segoe UI',
    Tahoma,
    Geneva,
    Verdana,
    sans-serif !important;
  color: var(--darkBlue) !important;


}

.react-datepicker__day-name {
  font-family: 'Segoe UI',
    Tahoma,
    Geneva,
    Verdana,
    sans-serif !important;
  color: var(--darkBlue) !important;


}

.react-datepicker__day--selected {
  color: var(--white) !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  color: var(--darkBlue);
  border-color: var(--darkBlue);
  border-radius: 3px;
  margin: 2px;
  cursor: pointer;
}


.MuiPaper-root {
  box-shadow: none !important;
  border-Radius: 8px;
  border: 1px solid var(--darkBlue);
  margin-top: 5px;
  color: var(--darkBlue);
  z-index: 99999999999999;

}

.MuiPaper-root li,
.MuiPaper-root svg {
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important; */
  font-family: 'Times New Roman',
    Times,
    serif !important;
  color: var(--darkBlue);
  font-size: 13px;

}

.MuiDataGrid-panelWrapper,
.MuiDataGrid-panelWrapper button,
.MuiDataGrid-panelWrapper input,
.MuiDataGrid-panelWrapper label,
.MuiDataGrid-panelWrapper svg,
.MuiDataGrid-panelWrapper select,
.MuiToolbar-root {
  color: var(--darkBlue) !important;
}

.MuiInputBase-root input {
  border-bottom: var(--darkBlue);

}

.Mui-disabled {
  -webkit-text-fill-color: rgba(55, 78, 154, 1) !important;
}

a {
  text-decoration: none;
}

.active button,
.active svg {
  background-color: var(--hover);

}

button:disabled {
  background-color: var(--backGroundDisabled);
  color: var(--colorDisabled);
  border: 2px solid var(--backGroundDisabled);
  cursor: default;
  /* border: none !important; */
}

button:disabled:hover {
  background-color: var(--backGroundDisabled);
  border: 2px solid var(--backGroundDisabled);

  color: var(--colorDisabled);
  cursor: default;
}

input:disabled {
  background-color: var(--white);
}

textarea:disabled {
  background-color: var(--white);
}

.SnackbarItem-message {
  font-size: 13.5px;
}


.MuiPopover-root.MuiMenu-rootMuiModal-rootMuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 999999;
}

#menu-searchFor {
  z-index: 9999999 !important;
}

#menu- {
  z-index: 9999999 !important;
}


.MuiDataGrid-cellContent {
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: 'Times New Roman',
    Times,
    serif !important;
}

.Joy-selected {
  background-color: #DEE6FF !important;
}

.Mui-selected {
  background-color: #DEE6FF !important;

}

.Joy-expanded {
  z-index: 9 !important;
}

.MuiSelect-root.Joy-expanded {
  border: 2px solid #374E9A !important;
}

.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused {
  border: 2px solid #374E9A !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
  border: 2px solid #374E9A !important;
}

#password,
#confirmPassword,
#newPassword,
#oldPassword {
  border: none !important;
}

.MuiInput-underline {
  font-size: 13px !important;
}

.MuiFormControl-root.MuiDataGrid-filterFormOperatorInput {
  display: none;
}

.MuiInputBase-root:before {
  border-bottom-color: var(--darkBlue) !important;
}

.MuiInputBase-root:before:hover {
  border-bottom-color: var(--darkBlue) !important;
}

.MuiInputBase-root:after {
  border-bottom-color: var(--darkBlue) !important;
}

.Mui-focused {
  border-bottom-color: var(--darkBlue) !important;
}

.MuiButtonBase-root,
.MuiTablePagination-selectLabel,
.MuiTooltip-tooltip {
  font-family: 'Times New Roman', Times, serif !important;
}

#searchStatus label {
  font-size: 18px !important;
  font-weight: bold;
}

#searchStatus #active {
  font-size: 16px !important;
}

.MuiTablePagination-actions button:disabled {
  border: none !important;
}

.MuiPaper-root.MuiAutocomplete-paper {
  background-color: #ffffff !important;
  box-shadow: none;
  border: 1px solid #374E9A;
  margin-top: 5px;
  border-radius: 4px;
  color: #374E9A;
  font-size: 13px;
  font-family: 'Times New Roman',
    Times,
    serif;
}

/* styling for updated row when new data come from socket */
.updated-row {
  background-color: var(--highlightBackground) !important;
  font-weight: bold;
}

/* loading color */
.MuiCircularProgress-svg {
  color: var(--darkBlue);
}

/* handle blue square when click on cell in table*/
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

/* handle height of select options */
.MuiMenu-list{
  max-height: 250px !important;
}
