:root{
    --blue:#2174F7;
    --white:#ffffff;
    --formCard:#F0F2F5;
    --darkBlue:#374e9a;
    /* --background:#EFF3FF; */
    --background:#e7e9f1;
    --hover:#DEE6FF;
    --hoverBtn:#4c8aed;
    --red:#cc3333;
    --hoverBackBtn:#4864c3;
    --green:#195b75;
    --brown:#c09a5d;
    --danger:#cc3333;
    --black:#000000;
    --colorDisabled:#cfcece;
    --backGroundDisabled:#6f6f6f;
    --highlightBackground:#ffffcc;
}